<template>
  <el-dialog
    title="刷新上报时间"
    :visible.sync="visible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div style="padding-bottom: 20px; color: #c52626;">
      <p>1、输入时间和位置，所选车辆按照输入的信息进行更新定位信息。</p>
      <p>
        2、如果未输入时间和位置，所选车辆最后定位时间随机生成(当前时间15天前以内)，位置取车辆最后定位位置，此操作只更新离线状态的车辆，其他状态车辆不做处理。
      </p>
      <p>3、不支持只输入一项信息。</p>
      <el-date-picker
        v-model="receiveTime"
        type="datetime"
        size="small"
        value-format="yyyy-MM-dd HH:mm:ss"
        style="width: 200px; margin-right: 20px;"
        placeholder="请选择上报时间"
      ></el-date-picker>
      <el-select
        v-model="address"
        placeholder="请输入详细地址"
        filterable
        remote
        :remote-method="handleInput"
        style="width: 200px;margin-right: 20px;"
        @change="handleChange"
        value-key="id"
        size="small"
        clearable
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.address"
          :value="item"
        >
        </el-option>
      </el-select>
      <!-- <el-button size="small" @click="getLocation">查询</el-button> -->
      <el-button size="small" @click="submit">确定</el-button>
    </div>
    <div id="container" v-if="visible"></div>
  </el-dialog>
</template>
<script>
import { AmapToGPS } from "@/utils/index";
let marker = null;
export default {
  name: "map-view",
  data() {
    return {
      map: null,
      visible: false,
      geocoder: null,
      address: "",
      receiveTime: "",
      lttd: "",
      lgtd: "",
      ids: "",
      autocomplete: "",
      placeSearch: "",
      options: [],
    };
  },
  methods: {
    handleInput(val) {
      this.placeSearch.search(val, (status, result) => {
        // 查询成功时，result即对应匹配的POI信息
        this.options = result.poiList.pois;
      });
    },
    show(ids) {
      this.ids = ids;
      this.visible = true;
      this.initAMap();
    },
    cancel() {
      this.map?.destroy();
      this.address = "";
      this.receiveTime = "";
      this.lttd = "";
      this.lgtd = "";
      this.ids = "";
      this.visible = false;
    },
    initAMap() {
      this.$nextTick(() => {
        this.map = new AMap.Map("container", {
          // 设置地图容器id
          zoom: 11, // 初始化地图级别
        });
        AMap.plugin(
          ["AMap.PlaceSearch", "AMap.Autocomplete", "AMap.Geocoder"],
          () => {
            this.geocoder = new AMap.Geocoder({
              city: "全国", // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
            });
            this.autocomplete = new AMap.Autocomplete({
              // input: "tipinput",
            });
            this.placeSearch = new AMap.PlaceSearch({
              // map: this.map,
            }); //构造地点查询类
          }
        );
        this.map.on("click", (val) => {
          let { lng, lat } = val.lnglat;
          this.createMarker(val.lnglat);
        });
      });
    },
    getLocation() {
      this.geocoder.getLocation(this.address, (status, result) => {
        if (status === "complete" && result.info === "OK") {
          // result中对应详细地理坐标信息
          this.createMarker(result.geocodes[0].location);
        } else {
          this.$message.error("输入地址有误，请重新输入!");
        }
      });
    },
    createMarker(lnglat) {
      if (marker) {
        this.map.remove(marker);
      }
      if (!lnglat) {
        this.lttd = "";
        this.lgtd = "";
        return;
      }
      let { lng, lat } = lnglat;
      this.lttd = lat;
      this.lgtd = lng;
      //创建一个 Marker 实例：
      marker = new AMap.Marker({
        position: new AMap.LngLat(lng, lat), //经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: "北京",
      });
      //将创建的点标记添加到已有的地图实例：
      this.map.add(marker);
      this.map.setFitView(marker);
    },
    handleChange(val) {
      let { lng, lat } = val.location;
      this.map.setZoomAndCenter(18, [lng, lat]); //同时设置地图层级与中心点
    },
    submit() {
      if (this.receiveTime || this.lttd) {
        if (!this.receiveTime) return this.$message.warning("请选择上报时间");
        if (!this.lttd) return this.$message.warning("请选择详细地址");
      }
      this.$confirm(
        `本操作会修改该设备的最后上线时间为当前时间，请谨慎操作，是否继续？`,
        "刷新上报时间",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let lnglat = this.lttd ? AmapToGPS([[this.lgtd, this.lttd]]) : null;
          let data = this.ids.split(",").map((el) => ({
            id: el,
            receiveTime: this.receiveTime ?? "",
            lttd: lnglat ? lnglat[0][1] : "",
            lgtd: lnglat ? lnglat[0][0] : "",
            direction: Math.floor(Math.random() * 361),
          }));
          return this.$http({
            url: this.$http.adornUrl("obd/lastreceivetime/reload"),
            method: "post",
            data,
          });
        })
        .then((res) => {
          this.$message.success(res?.msg ?? "操作成功");
          this.cancel();
        })
        .catch((err) => {
          if (err && err.msg) {
            this.$message.error(err.msg);
          }
        });
    },
  },
};
</script>
<style scoped>
#container {
  width: 100%;
  height: 500px;
}
</style>
